import { GetServerSideProps, NextPage } from 'next';
import { ErrorPageDocument, ErrorPageQuery } from '@adecco/base-app/src/gql/schema/generated';
import { withApolloProps } from '@adecco/base-app/src/apollo/withApolloProps';
import Custom404 from '@adecco/base-app/src/components/templates/404/404';
import Custom500 from '@adecco/base-app/src/components/templates/500/500';
import getConfig from 'next/config';

const Error: NextPage<{ errorCode: number }> = ({ errorCode }) =>
  errorCode === 404 ? <Custom404 /> : <Custom500 />;

export const getServerSideProps: GetServerSideProps = async (context) =>
  withApolloProps(async (client) => {
    const { publicRuntimeConfig } = getConfig();
    const errorCode = context.res.statusCode;

    const { data } = await client.query<ErrorPageQuery>({
      query: ErrorPageDocument,
      variables: {
        languages: publicRuntimeConfig.LANGUAGES,
        countryCode: context.locale ?? '',
      },
      errorPolicy: 'ignore',
    });

    return {
      props: {
        data,
        errorCode,
      },
    };
  });

export default Error;
